import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Privacy() {
    useEffect(() => {
      // Initialize AOS
      AOS.init();
  }, []);
  return (
    <>
      <section className="inner-banner">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Terms Of Service</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Terms Of Service</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="privacy-section all-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="section-top text-center">
                <h3 className="theme-h2">
                  <span> Terms of Service</span>
                </h3>
                <p>
                Welcome to IT Power Systems LLC. These Terms of Service <a href="/terms-of-service" target='_blank'> "Terms" </a> govern your access to and use of our website <a href="https://itpowersystems.com/" target='_blank'> itpowersystems.com </a> and any associated messaging or services provided by IT Power Systems LLC ("we," "us," "our"). By using our website, you agree to comply with these Terms. If you do not agree, please refrain from using our website.
                </p>
              </div>
            </div>
          </div>

          <span class="section-head">Effective Date: </span><p class="section-head-p">1st September, 2024</p>
          <div className="privacy-content">
            <span className="section-head">1. Acceptance of Terms</span>
            <p>
            By accessing or using our website, you agree to be bound by these Terms, our Privacy Policy, and any other applicable policies. If you are using our website on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.
            </p>
            <span className="section-head">2. Messaging Terms</span>
            <p>
            Our website may include features that allow you to communicate with us through contact forms, email, or other messaging tools. By using these messaging services, you agree to the following:
            </p>
            <ul>
                <li><h6 className="theme-h1"><span> Accuracy of Information :</span></h6> You will provide accurate, truthful, and up-to-date information when filling out forms or sending messages.</li>
                <li><h6 className="theme-h1"><span> Prohibited Use :</span></h6> You will not use the messaging services to send spam, offensive content, or any unlawful material.</li>
                <li><h6 className="theme-h1"><span> Communication Limits :</span></h6> We reserve the right to limit or block messages if we detect abuse or violations of these Terms.</li>
                <li><h6 className="theme-h1"><span> No Confidentiality :</span></h6> While we strive to protect your information as outlined in our Privacy Policy, messages sent through the website are not guaranteed to be confidential. </li>
                <li><h6 className="theme-h1"><span> Responsibility for Content :</span></h6> You are responsible for the content of your messages and agree not to infringe on the rights of others or violate any applicable laws.</li>
            </ul>
            <span className="section-head">3. Use of Website</span>
            <p>
            You agree to use our website for lawful purposes only. Specifically, you agree not to:
            </p>
            <ul>
              <li>
                <p>
                Modify, distribute, or reproduce any part of the website without our prior written consent.
                </p>
              </li>
              <li>
                <p>Interfere with the website’s operation, including by introducing malware or attempting unauthorized access.</p>
              </li>
              <li>
                <p>
                Use the website to harm or harass others.
                </p>
              </li>
            </ul>
            <span className="section-head">4. Intellectual Property</span>
            <p>
            All content on our website, including but not limited to text, images, graphics, and logos, is the intellectual property of IT Power Systems LLC and is protected by copyright, trademark, and other applicable laws. You may not use this content without prior written permission.
            </p>
            <span className="section-head">5. Limitation of Liability </span>
            <p>
            To the extent permitted by law, IT Power Systems LLC is not liable for any damages arising from:
            </p>
            <ul>
                <li>Your use or inability to use the website.</li>
                <li>Unauthorized access to your data.</li>
                <li>Any third-party content or services linked to or integrated with our website.</li>
            </ul>
            <span className="section-head">6. Indemnification</span>
            <p>
            You agree to indemnify and hold harmless IT Power Systems LLC, its affiliates, employees, and agents from any claims, damages, or expenses (including legal fees) arising from your violation of these Terms or misuse of the website.
            </p>
            <span className="section-head">7. Modifications to Terms</span>
            <p>
            We reserve the right to update or modify these Terms at any time. Changes will be effective upon posting to our website. Your continued use of the website after such changes constitutes acceptance of the updated Terms.
            </p>
            <span className="section-head">8. Termination</span>
            <p>
             We may suspend or terminate your access to the website at any time, without notice, for violations of these Terms or other policies.          
            </p>
            <span className="section-head">9. Governing Law</span>
            <p>
            These Terms are governed by the laws of the State of Texas, USA, without regard to its conflict of law principles. Any disputes arising under these Terms will be resolved in the courts located in Austin, Texas.            
            </p>
            <span className="section-head">10. Messaging Terms & Conditions</span>
            <p>You agree to receive informational messages (such as appointment reminders, account notifications, promotional offers, and updates) from IT Power Systems LLC ("we," "us," or "our"). By interacting with our messaging services, you acknowledge and agree to the following terms:</p>
            <p>Message Types: Messages may include account-related notifications, appointment reminders, promotional content, and other updates related to our services.</p>
            <p>Message Frequency: Message frequency varies and depends on your interaction with our services.</p>
            <p>Data Rates: Message and data rates may apply, as per your mobile carrier’s plan.</p>
            <p>Customer Care Contact: For assistance, you can reply HELP or email us at info@itpowersystems.com.</p>
            <p>Opt-Out Information: You may opt out at any time by replying STOP to our messages. After opting out, you will no longer receive further communications unless you re-subscribe.</p>


            <span className="section-head">11. Contact Information</span>
            <p>
            If you have questions or concerns about these Terms, please contact us:
             </p>
             <ul>
                <li>Company Name: IT Power Systems LLC</li>
                <li>Address: 5900 Balcones Drive #13507, Austin, TX 78731 USA</li>
                <li>Email: info@itpowersystems.com</li>
                <li>Phone: +1 (571) 622-0760</li>
             </ul>
             <p>Thank you for using IT Power Systems LLC. We value your trust and compliance with these Terms.</p>
          </div>
        </div>
      </section>
    </>
  );
}
