import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="content">
              <div className="logo">
                <img  className="img-fluid" src="assets/images/IT Power Systems Logo - White.png" alt="IT Power Systems" />
              </div>
              <p>
              Ready to transform your business with innovative digital solutions? Let's navigate the digital landscape together and achieve remarkable results. Contact us today to learn more about how IT Power Systems LLC can empower your business.
              </p>
            </div>
            <div className="col-lg-8">
            <div className="links-content pt-4">
              <div className="web-link social-link">
                <ul className="icon">
                  <li>
                    <a href="https://www.instagram.com/itpowersystems/" target="_blank">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61559295691668" target="_blank">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/itpowersystems/?viewAsMember=true" target="_blank">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          </div>
          <div className="col-lg-8">
            <div className="links-content pt-4">
              <div className="web-link pt-4">
                <h3>Explore</h3>
                <ul>
                  {/* <li>
                    <Link to="/">Home</Link>
                  </li> */}
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  {/* <li>
                    <Link to="/our-portfolio">Portfolio</Link>
                  </li> */}
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/our-blogs">Our Blogs</Link>
                  </li>
                  <li>
                    <Link to="/Privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-service">Terms of Service</Link>
                  </li>
                </ul>
              </div>
              <div className="web-link pt-4">
                <h3>Services</h3>
                <ul>
                  <li>
                    <Link to="/web-development">web development</Link>
                  </li>
                  <li>
                    <Link to="/graphic-design">logo design</Link>
                  </li>
                  <li>
                    <Link to="/ecommerce-web">E-Commerce</Link>
                  </li>
                  <li>
                    <Link to="/content-writer">Content Writing</Link>
                  </li>
                 
                </ul>
              </div>
              <div className="web-link pt-4">
                {/* <h3>Support</h3> */}
                <ul>
                <li>
                    <Link to="/graphic-design-blogdetail">graphic design</Link>
                  </li>
                  <li>
                    <Link to="/search-engine-optimization">SEO service</Link>
                  </li>
                  <li>
                    <Link to="/digital-marketing-service">Digital Marketing</Link>
                  </li>
                  <li>
                    <Link to="/uiux-design">UIUX Service</Link>
                  </li>
                  <li>
                    <Link to="/social-media-marketing">Social Media</Link>
                  </li>
                </ul>
              </div>
              <div className="web-link social-link">
                <h3>We Would Love To Hear From You</h3>
                <ul>
                  <li>
                    <a href="tel:+1 828-738-2210" target='_blank'> +1 828-738-2210</a>
                  </li>
                  <li>
                    <a href="https://wa.me/+15716220760" target='_blank'> +1 571-622-0760</a>
                  </li>
                  <li>
                    <a href="mailto:info@itpowersystems.com"> info@itpowersystems.com</a>
                  </li>
                  <li>
                    <a href="">
                     5900 Balcones Drive #13507 Austin, TX 78731 USA
                    </a>
                  </li>
                  {/* <li>
                    <a href="">
                    Cantonment Shopping Mall, Rashid Minhas Road, Faisal Cantonment Karachi.
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right">
        <p>© Copyright 2024 IT Power Systems LLC | All Rights Reserved.</p>
      </div>
    </footer>
  );
}
