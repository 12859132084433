import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Privacy() {
    useEffect(() => {
      // Initialize AOS
      AOS.init();
  }, []);
  return (
    <>
      <section className="inner-banner">
        <div className="banner-content">
          <div className="container">
            <div className="row banner-b justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 position-relative">
                <h2 className="theme-h2" data-aos="fade-right" data-aos-duration="3000"><span>Privacy Policy</span></h2>
                <p data-aos="fade-right" data-aos-duration="3000"> <Link to="/" >Home</Link> <i className="fa-solid fa-chevron-right"></i> Privacy Policy</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="privacy-section all-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="section-top text-center">
                <h3 className="theme-h2">
                  Privacy <span>Policy</span>
                </h3>
                <p>
                Welcome to IT Power Systems LLC. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website   <a href="https://itpowersystems.com/" target='_blank'> itpowersystems.com </a> or use our services.
                </p>
              </div>
            </div>
          </div>

          <span class="section-head">Effective Date: </span><p class="section-head-p">1st September, 2024</p>
          <div className="privacy-content">
            <span className="section-head">1. Information We Collect</span>
            <p>
            We collect information to improve our services, personalize your experience, and provide relevant content. Specifically, we collect:
            </p>
            <ul>
              <li>
                <p>
                  <span className="section-head">Personal Information :</span>
                  When you fill out our contact form, we may collect your name, email address, phone number, and any additional information you voluntarily provide.
                </p>
              </li>
              <li>
                <p>
                  <span className="section-head">Technical and Usage Data :</span>
                  We collect data through tools like Google Analytics and Facebook Pixel to analyze user interactions with our website. This data may include your IP address, browser type, device type, pages visited, time spent on the website, and other similar information.
                  </p>
              </li>
              </ul><br></br>
              <p>We do not collect or process any payment information on our website.</p>
            <span className="section-head">
            2. How We Use Your Information
            </span>
            <p>
              We use the information we collect for the following purposes:
              <ul>
              <li>
                <p>To respond to inquiries submitted through the contact form.</p>
              </li>
              <li>
              <p>To analyze website performance and user behavior for improving our website and services.</p>
              </li>
              <li>
              <p>To carry out marketing campaigns and provide targeted advertisements using tools like Google Analytics and Facebook Pixel.</p>
              </li>
              <li>
              <p>To comply with legal obligations and ensure the security of our website.</p>
              </li>
              </ul>
            </p>
            
            <span className="section-head">3. Sharing of Information</span>
            <p>
               We do not sell, rent, or trade your personal information. However, we may share your information with:
            </p>
            <span className="section-head">Service Providers :</span>
            <p>
            Third-party vendors who help us analyze website data (e.g., Google Analytics, Facebook Pixel).
            </p>
            <span className="section-head">Legal Authorities :</span>
            <p>
              When required by law or to protect our rights and the security of our website. </p>

            <span className="section-head">4. Cookies and Tracking Technologies</span>
            <p>
            We use cookies and similar tracking technologies to enhance your experience and analyze website traffic. These include:
            </p>
            <ul>
              <li>Google Analytics Cookies:</li><p>For website performance tracking.</p>
              <li>Facebook Pixel :</li><p>For targeted marketing and advertising.</p>
            </ul>
            <p>You can control your cookie preferences through your browser settings.</p>

            <span className="section-head">5. Data Security</span>
            <p>
            We implement reasonable technical and organizational measures to protect your personal information from unauthorized access, loss, or misuse. However, no method of data transmission or storage is 100% secure. If you believe your information has been compromised, please contact us immediately.
            </p>
            <span className="section-head">6. User Rights</span>
            <p>
            Depending on your location, you may have the following rights regarding your personal information:
            </p>
            <ul>
              <li>The right to access, update, or delete your data.</li>
              <li>The right to opt out of marketing communications.</li>
              <li>The right to request information about how your data is processed.</li>
            </ul>
            <p>To exercise these rights, please contact us at <span>info@itpowersystems.com</span></p>
            <span className="section-head">7. Children's Privacy</span>
            <p>
            Our website and services are not intended for individuals under 13 years of age. We do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided us with personal information, please contact us to request its deletion.
            </p>
            <span className="section-head">8. Changes to This Privacy Policy</span>
            <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. Any updates will be posted on this page with the "Effective Date" updated. We encourage you to review this Privacy Policy periodically.          
            </p>
            <span className="section-head">9. Messaging Privacy Policy</span>
            <p>At IT Power Systems LLC ("we," "us," or "our"), we prioritize the privacy of our customers and are committed to protecting your personal information. By using our messaging services, you agree to the terms outlined in this Messaging Privacy Policy.</p>
            <span className="section-head">10. Use and Sharing of Information</span>
            <p>No mobile information collected from you will be shared with any third parties or affiliates for marketing or promotional purposes.</p>
            <p>All other categories of data exclude text messaging originator opt-in data and consent. This information will not be shared with or obtained by any third parties.</p>
            <span className="section-head">11. Data Collection and Use</span>
            <p>We may collect and use your mobile phone number solely for the purpose of sending you informational messages, such as appointment reminders, account notifications, and promotional updates related to our services.</p>
            <span className="section-head">12. Opt-Out Instructions</span>
            <p>If you wish to stop receiving messages from us, you can opt out at any time by replying STOP to our messages.</p>
            <p>Alternatively, you can contact us at info@itpowersystems.com to request removal from future communications.</p>

            <span className="section-head">13. Contact Information</span>
            <p>
            If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us:
            </p>
            <ul>
              <li>Company Name: IT Power Systems LLC</li>
              {/* <li>EIN Number: 30-1427855</li> */}
              <li>Address: 5900 Balcones Drive #13507, Austin, TX 78731 USA</li>
              <li>Email: info@itpowersystems.com</li>
              <li>Phone: +1 (571) 622-0760</li>
            </ul>
            <p>
            Thank you for trusting IT Power Systems LLC with your information.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
